<template>
  <div class="container">
    <div style="font-size: 20px;margin-bottom:20px;">满额包邮设置</div>
    <div style="width: 100%; height: 2px; background: #D7D7D7"></div>

    <div style="padding: 20px  0">
      <div>
        <span style="margin-right: 15px">是否启用</span>
        <el-switch v-model="switch_value"></el-switch>
      </div>
      <div style="color: #ff7e28; margin: 20px 0">
        开启满额包邮，订单总额满足指定金额可以包邮
      </div>
      <div>
        <span>单笔订单满</span>
        <el-input-number
          v-model="free_shipping_money"
          controls-position="right"
          placeholder="请输入金额"
          style="margin: 0 8px 0 15px"
        ></el-input-number>
        <span>元</span>
      </div>
      <div style="color: #ff7e28; margin: 20px 0">全场包邮请填0元</div>
      <div>
        <span>设置不包邮地区</span>
        <el-button
          type="primary"
          style="width: 150px; margin-left: 20px"
          @click="showarea"
          >选择</el-button
        >
      </div>
      <div
        style="
          width: 100%;
          height: 238px;
          background: #FAFAFA;
          margin-top: 20px;
          padding: 20px 0 0 20px;
        "
      >
        {{ free_shipping_citys_str }}
      </div>
    </div>
    <div style="text-align: center; margin-top: 30px; padding-bottom: 20px">
      <el-button type="primary" style="width: 152px" @click="submit"
        >保存</el-button
      >
    </div>

    <!-- 选择区域弹窗 -->
    <el-dialog
      title="选择区域"
      :visible.sync="dialogVisible"
      width="728px"
      style="text-align: left"
      :close-on-click-modal="false"
    >

      <div class="areabox">
        <div
          class="areaitem"
          v-for="(area, index) in areaData"
          :key="index"
          ref="checkedbg"
        >
          <div class="checkpro">
            <el-checkbox
              v-model="area.isChecked"
              @change="
                (val) => {
                  return changebg(val, index);
                }
              "
              >{{ area.area_name }}</el-checkbox
            >
          </div>
          <div class="provinceitem">
            <div v-for="(pro, ind) in area.province" :key="ind">
              <span
                @click="choosePro(pro, area, index)"
                :style="{ color: pro.isChecked ? '#1890ff' : '#333' }"
                >{{ pro.name }}</span
              >

              <span
                v-for="(dis, i) in pro.city"
                :key="i"
                :style="{ color: dis.isChecked ? '#1890ff' : '#999' }"
                @click="chooseCity(dis, pro, area, index, ind)"
                >{{ dis.name }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          plain
          @click="dialogVisible = false"
          style="width: 100px; margin-top: 20px"
          >取消</el-button
        >
        <el-button
          type="primary"
          @click="chooseAdd"
          style="width: 100px; margin-top: 20px"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      switch_value: false,
      is_free_shipping: "",
      free_shipping_money: 0,
      areaData: [],
      dialogVisible: false,
      free_shipping_provinces: "",
      free_shipping_citys: "",
      free_shipping_citys_str: "",
    };
  },
  methods: {
    //获取店铺满额包邮信息
    getFullShipping() {
      this.axios
        .post("/store/Shopshipping/getFreeShippingSetting")
        .then((res) => {
          let info = res.data.info;
          this.free_shipping_money = info.free_shipping_money;
          if (info.is_free_shipping) {
            this.switch_value = true;
          }
          this.free_shipping_citys_str = info.free_shipping_citys_str;
          this.free_shipping_provinces = info.free_shipping_provinces || "";
          this.free_shipping_citys = info.free_shipping_citys || "";
        });
    },
    //保存
    submit() {
      let data = {
        free_shipping_money: this.free_shipping_money,
        free_shipping_provinces: this.free_shipping_provinces,
        free_shipping_citys: this.free_shipping_citys,
        free_shipping_citys_str: this.free_shipping_citys_str,
        is_free_shipping: 0,
      };
      if (this.switch_value) {
        data.is_free_shipping = 1;
      }
      this.axios
        .post("/store/Shopshipping/freeShippingSetting", data)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success("保存成功");
            this.getFullShipping();
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //取消
    cancel() {
      this.switch_value = false;
      this.free_shipping_money = "";
      this.free_shipping_citys_str = "";
      this.free_shipping_provinces = "";
      this.free_shipping_citys = "";
    },
    //获取大区-省份-城市-区县
    getAreaList() {
      this.axios
        .post("/store/platform/getAreaProvinceCity")
        .then((res) => {
          let areaData = res.data.list;
          areaData.forEach((item) => {
            item.isChecked = false;
            item.province.forEach((pro) => {
              pro.isChecked = false;
              pro.city.forEach((city) => {
                city.isChecked = false;
              });
            });
          });
          this.areaData = areaData;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //编辑区域弹窗
    showarea() {
      let pro_id = [];
      let city_id = [];

      if (!this.free_shipping_provinces && !this.free_shipping_citys) {
        pro_id = [];
        city_id = [];
      } else {
        pro_id = this.free_shipping_provinces.split(",");
        city_id = this.free_shipping_citys.split(",");
      }

      this.areaData.forEach((item) => {
        item.isChecked = false;
        let a = 0;
        item.province.forEach((pro) => {
          pro.name = pro.name.replace("(部分地区)", "");
          pro.isChecked = false;
          if (pro_id.includes(pro.province_id + "")) {
            pro.isChecked = true;
            a = 1;
          }
          pro.city.forEach((city) => {
            city.isChecked = false;
            if (city_id.includes(city.city_id + "")) {
              city.isChecked = true;
            }
          });
        });
        if (a) {
          item.isChecked = true;
        }
      });
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.checkedbg.forEach((i) => {
          i.style.background = "white";
        });
      });
    },
    //选择大区
    changebg(val, index) {
      this.areaData[index].isChecked = val;
      if (val) {
        this.$refs.checkedbg[index].style.background = "#e6f7ff";
        this.areaData[index].province.forEach((i) => {
          i.isChecked = true;
          i.city.forEach((j) => {
            j.isChecked = true;
          });
        });
      } else {
        this.$refs.checkedbg[index].style.background = "white";
        this.areaData[index].province.forEach((i) => {
          i.isChecked = false;
          i.city.forEach((j) => {
            j.isChecked = false;
          });
        });
      }
    },
    //选择省
    choosePro(pro, area, index) {
      pro.isChecked = !pro.isChecked;
      area.isChecked = false;
      this.$refs.checkedbg[index].style.background = "white";
      pro.city.forEach((ele) => {
        ele.isChecked = pro.isChecked;
      });
      this.areaData[index].province.forEach((i) => {
        let a = 0;
        if (i.isChecked) {
          a = 1;
        }
        if (a) {
          area.isChecked = true;
          this.$refs.checkedbg[index].style.background = "#e6f7ff";
        }
      });
    },
    //选择市
    chooseCity(dis, pro, area, index, ind) {
      pro.isChecked = false;
      area.isChecked = false;
      this.$refs.checkedbg[index].style.background = "white";
      dis.isChecked = !dis.isChecked;
      this.areaData[index].province[ind].city.forEach((i) => {
        let a = 0;
        if (i.isChecked) {
          a = 1;
        }
        if (a) {
          pro.isChecked = true;
        }
      });
      this.areaData[index].province.forEach((i) => {
        let a = 0;
        if (i.isChecked) {
          a = 1;
        }
        if (a) {
          area.isChecked = true;
          this.$refs.checkedbg[index].style.background = "#e6f7ff";
        }
      });
    },
    //选择的地区
    chooseAdd() {
      let proData = [];
      let cityData = [];
      this.areaData.map(area => {
        if (area.isChecked) {
          area.province.map(pro => {
            if (pro.isChecked) {
              let a = 0;
              let city_name = "";

              pro.city.map(city => {
                if (!city.isChecked) {
                  a = 1;
                } else {
                  city_name += city.name + ",";
                }
              });
              if (a) {
                pro.name =
                  pro.name +
                  "(" +
                  city_name.substr(0, city_name.length - 1) +
                  ")";
              }
              proData.push(pro);
            }
            pro.city.map(city => {
              if (city.isChecked) {
                cityData.push(city);
              }
            });
          });
        }
      });
      let pro_id = [];
      let city_id = [];
      let pros = [];
      pros = proData.map(item => {
        return item.name;
      });
      pro_id = proData.map(item => {
        return item.province_id;
      });
      city_id = cityData.map(item => {
        if (item.isChecked) {
          return item.city_id;
        }
      });
      this.free_shipping_provinces = pro_id.toString();
      this.free_shipping_citys = city_id.toString();
      this.free_shipping_citys_str = pros.toString();

      this.dialogVisible = false;
    }
  },
  created() {
    this.getFullShipping();
    this.getAreaList();
  },
};
</script>
<style lang="scss" scoped>
.areabox {
  width: 668px;
  height: 446px;
  border: solid 1px #c9cbd6;
  margin: 0 auto;
  margin-top: 20px;
  overflow-y: scroll;
  .areaitem {
    display: flex;
    border-bottom: 2px solid #e8e9f0;
    .checkpro {
      width: 74px;
      border-right: 2px solid #e8e9f0;
      text-align: center;
      margin-right: 10px;
      padding-top: 1.8%;
    }
    .provinceitem {
      div {
        margin: 10px 0;
        width: 550px;
      }
      span {
        margin-right: 15px;
        display: inline-block;
        margin-bottom: 5px;
        color: #999;
        cursor: pointer;
      }
      span:first-child {
        color: #333;
        padding-right: 10px;
        border-right: 2px solid #e8e9f0;
        margin-bottom: 5px;
      }
    }
  }
}
</style>
<style>
/* .el-dialog__body {
  padding: 0 20px;
} */
</style>